import React from 'react';
import SEO from "../common/SEO";
import Headerthree from '../common/header/Headerthree';
import FooterThree from '../common/footer/FooterThree';
import { FiDownload } from "react-icons/fi";
import Education from "../elements/about/Education";
import PersonalInfo from "../elements/about/PersonalInfo";
import '../assets/css/prof.css';

<br />
const Apropos = () => {
    return (
        <>
            <SEO title="Apropos OMAR TEOUATI" />
            <main className="page-wrapper">
                <Headerthree btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />



    <section className="main-content ">
      <div className="container">
        <div className="row">
          {/* Personal Info Starts */}
<br />
          <div className="col-xl-6 col-lg-5 col-12">
            <div className="row">
            <br/>
              <div className="col-12">
                <h3 className="text-uppercase custom-title mb-0 ft-wt-600">
                Informations personnelles
                </h3>
              </div>
              {/* End .col */}
              </div>
              {/* image for mobile menu */}
<br />
              <div className="col-12 d-block d-sm-none" >
                <img
                  src="./images/portfolio/mobile.png"
                  className="_img-mobile"
                  alt="OMAR Teouati"
                />
              </div>

              <div className="col-12">
                <PersonalInfo />
              </div>
              {/* End personal info */}
              <div className="col-12 mt-1">
               
                <a className="btn-default btn-medium btn-border round btn-icon" href="/OMAR_TEOUATI.pdf" download>
                  <span className="button-text">Télécharger CV</span>
                  <i className="icon"><FiDownload /></i>
                </a>
              </div>
              {/* End download button */}
            </div>
          </div>
          {/*  Personal Info Ends */}

          {/*  Boxes Starts */}
          <div className="col-xl-6 col-lg-5 col-12 mt-5 mt-lg-0">
           {/*   <Achievements />  */}  
           < br />< br />
          
          {/* Achievements Ends */}
        </div>
        {/* End .row */}
<br />
        <hr className="separator mt-1" />

        {/*  Education Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
            Formation
            </h3>
          </div>
          <div className="col-lg-6 m-15px-tb">
            <div className="resume-box">
              <Education />
              
            </div>
          </div>
        </div>
        {/* Education Ends */}
      </div>
    </section>







<br />


                <FooterThree />

            </main>
        </>
    )
}

export default Apropos;
