import React from 'react';
import Headerthree_3 from "./header/Headerthree_3";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";

const Layouttwo = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <Headerthree_3 btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Layouttwo;
