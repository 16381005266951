import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiChevronsRight } from "react-icons/fi";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TabOne from '../elements/tab/TabOne';
import BrandTwo from '../elements/brand/BrandTwo';
import TimelineTwo from "../elements/timeline/TimelineTwo";
import TeamFour from '../elements/team/TeamFour';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";


const Marketing = () => {
    return (
        <>
            <SEO title="OMAR" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                    <h1 className="title theme-gradient display-two"> <br />{" "}
                                        <Typed
                                            strings={[
                                                "Développeur ",
                                                "Web",
                                                "Front-end",
                                                "Back-end",
                                                "Full stack",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Licence professionnelle en développement informatique Ingenierie de conception et de developpement d’applications</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/Apropos">
                                        À propos de moi<i className="icon"><FiChevronsRight /></i>                        
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/Qui_suis_je/omar.png" alt=" Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
                <Separator   />
<div id="Experiences_pro">
                <div className="rwt-elements-area rn-section-gap"  >
                    <div className="container-fluid plr--30" >
                        <div className="row">
                            <div className="col-lg-12" >
                                <SectionTitle 
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "EXPÉRIENCES PROFESSIONNELLES"/>
                            </div>
                        </div>
            
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="dark-line"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                            <div className="col-lg-12" >
                                <SectionTitle 
                                    textAlign = "text-center"
                                    title = "PROJETS"/>
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay" />
                    
 </div>
        

                {/* Start Service Area  */}
                <div id="certifications">
                    <div className="rn-service-area rn-section-gap" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "CERTIFICATIONS"
                                        description = ""
                                        />
                                </div>
                            </div>
                            <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />

                        </div>
                    </div>
                </div>
                <div id="compétence">
                {/* End Service Area  */}

                {/* Start Connaissances Area Connaissances techniques */}
           
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    title = "Compétence"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
                <div id="centre_d’intérêt">
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    title = "CENTRE D’INTÉRÊT"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                            <TabOne />
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                {/* End Brand Area  */}

                <FooterThree />

            </main>
        </>
    )
}

export default Marketing;
