import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabOne = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/connaissances_techniques/centre_gym.png' alt="CENTRE D’INTÉRÊT" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Sport</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Langues</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p> 
Depuis mon enfance j'étais fasciné par plusieurs types de sports et du coup j'ai choisi de pratiquer le judo, la natation ,le basketball ,la musculation et le cyclysme . </p>

                                                        <p></p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                       
                                                        <p> <li>Anglais  : Moyen</li>
                                                            <li>Français : Moyen</li>
                                                            <li>Arabe    : Maternelle</li>
                                                        </p>

                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabOne
