import React from 'react';
import {Link} from "react-router-dom";

const Navtwo = () => {
    return (
        <ul className="mainmenu">
            <li><a href="./#Qui_suis_je">QUi SUIS-JE?</a></li>
            <li><a href="./#Experiences_pro">EXPÉRIENCES PRO</a></li>
            <li><a href="./#compétence">COMPÉTENCE</a></li>
            <li><a href="./#certifications">CERTIFICATIONS</a></li>
            <li><a href="./#centre_d’intérêt">CENTRE D’INTÉRÊT</a></li>
        </ul>
    )
}
export default Navtwo;
