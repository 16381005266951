import React from 'react';

const BrandList = [
    {
        image: './images/connaissances_techniques/whm.png'
    },
    {
        image: './images/connaissances_techniques/azure.png'
    },
    {
        image: './images/connaissances_techniques/cyberpanel.png'
    },
    {
        image: './images/connaissances_techniques/litespeed.png'
    },
    {
        image: './images/connaissances_techniques/cpanel.png'
    },
    {
        image: './images/connaissances_techniques/noc.png'
    },
    {
        image: './images/connaissances_techniques/oracle.png'
    },
    {
        image: './images/connaissances_techniques/sqlserver.png'
    },
    {
        image: './images/connaissances_techniques/mysql.png'
    },
    {
        image: './images/connaissances_techniques/Access.png'
    },
    {
        image: './images/connaissances_techniques/sqllite.png'
    },
    {
        image: './images/connaissances_techniques/cloudflaire.png'
    },
    {
        image: './images/connaissances_techniques/html&css.png'
    },
    {
        image: './images/connaissances_techniques/bootstrap.png'
    },
    {
        image: './images/connaissances_techniques/javascript.png'
    },
    {
        image: './images/connaissances_techniques/jquery.png'
    },
    {
        image: './images/connaissances_techniques/ajax.png'
    },
    {
        image: './images/connaissances_techniques/json.png'
    },
    {
        image: './images/connaissances_techniques/java.png'
    },
    {
        image: './images/connaissances_techniques/putho.png'
    },
    {
        image: './images/connaissances_techniques/c.png'
    },
    {
        image: './images/connaissances_techniques/ASP.png'
    },
    {
        image: './images/connaissances_techniques/php.png'
    },
    {
        image: './images/connaissances_techniques/react.png'
    },
    {
        image: './images/connaissances_techniques/laravel.png'
    },
    {
        image: './images/connaissances_techniques/django.png'
    },
    {
        image: './images/connaissances_techniques/worddpress.png'
    }, 
    {
        image: './images/connaissances_techniques/wocommerce.png'
    }, 
    {
        image: './images/connaissances_techniques/oxygen.png'
    },
    {
        image: './images/connaissances_techniques/wpbakery.png'
    },
    {
        image: './images/connaissances_techniques/elmentor.png'
    }, 
    {
        image: './images/connaissances_techniques/yastseo.png'
    },
    {
        image: './images/connaissances_techniques/photshop.png'
    }
    
]

const BrandTwo = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Connaissances techniques" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandTwo;
